<h1 mat-dialog-title>{{ 'signal.can.add.plural' | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <div class="w-full">
        <div class="mat-subtitle-2">
            {{ 'message.select' | translate }}
        </div>
        <sz-select
            [formControl]="messageControl"
            [options]="messageOptions"
            [groups]="selectGroup"
        />
    </div>
    @if (signals?.length > 0) {
        <div>
            <div class="mat-subtitle-2">
                {{ 'common.search' | translate }}
            </div>
            <sz-input [formControl]="filterControl" />
        </div>
    }
    <div class="btn-bar mt-4">
        @if (signals?.length > 0) {
            <sz-button
                size="medium"
                text="select.all"
                bgColor="primary"
                icon="check-all"
                (click)="selectAllSignals()"
            />
        }
        @if (signals?.length === 0) {
            <p>
                {{ 'message.signal.none.plural' | translate }}
            </p>
        }
    </div>
    <div class="mt-2 h-[40vh] overflow-auto">
        @if (messageControl.value) {
            <mat-selection-list class="full-width">
                @for (
                    signal of signals | search: filterTerm : ['name'];
                    track signal.id
                ) {
                    <mat-list-option
                        class="mr-2 flex !rounded-md"
                        (click)="toggleSignal(signal)"
                        [selected]="isSignalSelected(signal)"
                        [value]="signal"
                    >
                        {{ signal.name }}
                    </mat-list-option>
                }
            </mat-selection-list>
        }
    </div>
    <sz-dialog-button-bar
        primaryText="common.select"
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
